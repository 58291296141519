<template>
  <v-container>
    <v-row v-if="connected">
      create tourney..
    <tournament-bar ref="inviteBar" />
    </v-row>
    <v-row>
      <v-col>
        Tourney list...<br>
        <span v-for="t in tournaments" :key="t.id" @click="select(t.id,t)">
          {{ t }}<br>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-column pa-5">
        <p class="text-h4 font-weight-bold mb-0">TOURNEY LIST</p>
        <p class="text-h6 font-weight-medium">
          Tournament State: Waiting to fill up<br>
          <v-btn v-if="selected" @click="doSim(selected.id)">
            Sim result
          </v-btn>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="selected" class="pa-md-10">
      <v-col md="8">
        <v-card
          style="border: 9px solid #2a2e34; border-radius: 25px"
          height="483"
          width="960"
        >
          <v-row class="tournament">
            <v-col cols="4" class="round pa-0">
              <template v-for="n in selected.slots">
                <div :key="n" class="match">
                  <div class="match__content">
                    <div class="q" />
                    <tourney-slot :id="selected.id" :nslot="n-1" :info="selected.players[n-1]" :game="selected.game" />
                  </div>
                </div>
              </template>
            </v-col>
            <v-col cols="4" class="round pa-0">
              <template v-for="n in selected.slots / 2">
                <div :key="n" class="match">
                  <div class="match__content">
                    <div class="q" />
                    <tourney-slot :unk="true" :info="selected.players[n-1]" />
                  </div>
                </div>
              </template>
            </v-col>
            <v-col cols="4" class="round pa-0">
              <template v-for="n in selected.slots / 4">
                <div :key="n" class="match">
                  <div class="match__content">
                    <div class="q" />
                    <tourney-slot :unk="true" :info="selected.players[n-1]" />
                  </div>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card
          style="border: 9px solid #2a2e34; border-radius: 25px"
          height="483"
          width="960"
        >
          MAYBE CREATE AN LIST WITH ALL PLAYERS PARTICIPATION IN THIS TOURNEY,
          WITH A BUTTON TO JOIN THE TOURNEY.
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'Tournaments',
    components: {
      TournamentBar: () => import('../../components/core/TournamentBar'),
      TourneySlot: () => import('../../components/tournaments/Slot1'),
    },
    data () {
      return {
        x: {},
        selectId: 0,
        selected: null,
      }
    },
    computed: {
      ...mapState('platform', ['mainHost', 'tournaments']),
      ...mapState('wallet', ['connected']),
    },
    watch: {
      node () {
        // this.getGames()
        console.log('nchange')
      },
    },
    async mounted () {
      this.getGames()
    },
    methods: {
      async doSim (id) {
        var ret = (await this.$http.get(this.mainHost + '/tournament?sim=true&id=' + id)).data
        this.sim = ret
        console.log('do sim', ret)
      },
      ...mapActions('wallet', ['signMsg']),
      ...mapActions('platform', ['getTournaments', 'signNodeMsg']),
      select (id, tourney) {
        this.selectId = id
        this.selected = tourney
      },
      pickSlot (slot) {
        console.log('pick slot', this.selectId, slot)
        this.signMsg({ msg: 'join_tournamnet', slot, id: this.selectId })
      // send ws join slot?
      },
      async getGames () {
        await this.getTournaments()
        // this.selected = this.tournaments[0]
      },
    },
  }
</script>

<style scoped>
.tournament {
  padding: 30px 25px;
}

.round {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.round:first-child .match::before {
  display: none;
}

.round:first-child .q::before {
  display: none;
}

.round:first-child .match__content::before {
  display: none;
}
.round:last-child .match::after {
  display: none;
}
.match {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  flex-grow: 1;
  position: relative;
  width: 232px;
  height: 43px;
  margin-bottom: 4px !important;
}

.q::before {
  content: "";
  display: block;
  top: 45%;
  left: -63px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #e36529;
  position: absolute;
}

.match::before {
  /* content: "";
  display: block;
  min-height: 30px;
  border-left: 2px solid #e36529;
  position: absolute;
  left: -10px;
  top: 50%;
  margin-top: -15px;
  margin-left: -2px; */
}
.match:nth-child(odd)::after {
  /* content: "";
  display: block;
  border: 2px solid transparent;
  border-top-color: #e36529;
  border-right-color: #e36529;
  height: 50%;
  position: absolute;
  right: -10px;
  width: 10px;
  top: 50%; */
}
.match:nth-child(even)::after {
  /* content: "";
  display: block;
  border: 2px solid transparent;
  border-bottom-color: #e36529;
  border-right-color: #e36529;
  height: 100%;
  position: absolute;
  right: -10px;
  width: 10px;
  bottom: 50%; */
}

.match:nth-child(even) {
  margin-bottom: 16px !important;
}

.match__content::before {
  content: "";
  display: block;
  width: 63px;
  border-bottom: 2px solid #e36529;
  margin-left: -2px;
  position: absolute;
  top: 47%;
  left: -61px;
}
</style>
